import {routesPosition, RoutesProps} from "../router.config";
import Kanban, {KanbanColumn} from "../../components/Kanban/Kanban";
import ShipmentKanbanCard from "../../components/app/App/ShipmentKanbanCard";
import {ColumnImportConstants} from "../../constants/model";
import ImportType from "../../forms/app/types/ImportType";
import {Profiles} from "../../model/Group";
import {TicketNormalizer} from "../../services/normalizer/ticketNormalizer";
import FilterController from "../../components/FilterController";
import ImportFilter from "../../forms/app/filters/ImportFilter";

const columns: KanbanColumn[] = [];

Object.entries(ColumnImportConstants).forEach(([, value]) => {
    switch (value) {
        case ColumnImportConstants.NEW_CASE:
            columns.push({label: 'Nouveau Dr', col: value})
            break;
        case ColumnImportConstants.LOADING:
            columns.push(
                {
                    label: 'FOB',
                    col: value,
                    orderBy: {'shipment.loadingRealDate': 'ASC', 'shipment.loadingEstimatedDate': 'ASC'}
                }
            )
            break;
        case ColumnImportConstants.ARRIVAL:
            columns.push(
                {
                    label: 'Arrivée',
                    col: value,
                    orderBy: {'shipment.unloadingRealDate': 'ASC', 'shipment.oiUnloadingPredictiveDate': 'ASC','shipment.akaneaUnloadingInitialDate': 'ASC'}
                }
            )
            break;
        default:
            columns.push({label: value, col: value})
    }
})

export const importRoutes: RoutesProps = {
    icon: 'fas fa-plane-arrival',
    name: 'Import',
    path: '/import/',
    position: routesPosition.leftSideBar,
    handle: {
        crumb: 'Import',
    },
    accessControl: [Profiles.ROLE_USER, Profiles.ROLE_ADMIN, Profiles.ROLE_SUPER_ADMIN],
    children: [
        {
            path: '/import/',
            element: <FilterController
                renderFilterable={(props) => <Kanban
                    {...props}
                    renderCard={(ticket) => <ShipmentKanbanCard ticket={ticket}/>}
                    columns={columns}
                    colSize={2}
                    namespace={'workflow_tickets'}
                    normalizer={TicketNormalizer}
                />}
                filterDefault={{category: 'import', 'shipment+archived': false, managedByMe: true}}
                renderFilter={(props) => <ImportFilter {...props} />}
            />,
            position: routesPosition.hiddenRoutes
        },
        {
            path: '/import/:id',
            element: <ImportType/>,
            handle: {
                crumb: 'Dossier',
            },
            position: routesPosition.hiddenRoutes,
        }
    ]
};
