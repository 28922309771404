import React, {ReactNode, Reducer, useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Row} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import {DragDropContext, DroppableProvided, DropResult,} from '@hello-pangea/dnd';
import classNames from 'classnames';
import {Ticket} from '../../model/interface/Ticket';
import {arrayIndexFormat, titleFormat} from "../../utils/string";
import {StrictModeDroppable} from "../StrictModeDroppable";
import useDatasource, {FilterType, OrderType} from "../../hooks/useDatasource";
import {Normalizer} from "../../services/normalizer/normalizer";
import Column from "./Column";
import InfiniteListingController from "../InfiniteListingController";
import {renderFilterableProps} from "../FilterController";

// begin stackoverflow
// https://stackoverflow.com/questions/39494689/is-it-possible-to-restrict-number-to-a-certain-range
type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>
// end stackoverflow

type columnsBeforeScrollRange = IntRange<1, 13>

export interface KanbanColumn {
    label: string,
    col: string,
    // compare "a" avec "b" pour du "asc" et "b" avec "a" pour du desc
    orderBy?: OrderType,
}

export interface KanbanProps<ResourceT extends Ticket> extends renderFilterableProps {
    colSize?: columnsBeforeScrollRange,
    columns: KanbanColumn[],
    renderCard: (cardProps: ResourceT) => ReactNode,
    namespace: string,
    normalizer: Normalizer<ResourceT>,
}

function hasFalseValue<T>(obj: T) {
    for (const key in obj) {
        if (!obj[key]) {
            return true;
        }
    }
    return false;
}


enum ResourceActionsType {
    ADD = 'add',
    MOVE = 'move',
    REPLACE = 'replace',
    EMPTY = 'empty'
}

interface ResourceAction<T extends Ticket> {
    type: ResourceActionsType,
    column?: string
    payload: T[] | T
    from?: string
    to?: string
    id?: number
}

interface ResourceState<T extends Ticket> {
    [key: string]: T[]
}

function resourceReducer<T extends Ticket>(state: ResourceState<T>, action: ResourceAction<T>) {
    switch (action.type) {
        case ResourceActionsType.ADD:
            if (!action.column || !action.payload || !Array.isArray(action.payload)) {
                return state
            }
            return {
                ...state,

                [action.column]: [
                    ...state[action.column],
                    ...action.payload.filter((payload) =>
                        !state[action.column || ''].some(item => item.id === payload.id)
                    )
                ]
            }
        case ResourceActionsType.REPLACE:
            if (!action.column || !action.payload || !Array.isArray(action.payload)) {
                return state;
            }

            return {
                ...state,
                // On remplace les éléments de la colonne par le nouveau tableau d'éléments
                [action.column]: [...action.payload]
            };
        case ResourceActionsType.EMPTY:
            if (!action.column) {
                return state;
            }

            return {
                ...state,
                [action.column]: []
            };
        case ResourceActionsType.MOVE:
            if (!action.from || !action.to || Array.isArray(action.payload)) {
                return state
            }

            const id = action.payload.id;

            const ticketIndex = state[action.from].findIndex(el => el.id === id);

            if (ticketIndex === -1) {
                return state; // Si l'élément n'est pas trouvé, ne pas modifier l'état
            }

            return {
                ...state,
                // on supprime le ticket de la colonne d'origine
                [action.from]: [...state[action.from].toSpliced(ticketIndex, 1)],
                // on ajoute celui de la réponse dans la nouvelle
                [action.to]: [...state[action.to], action.payload]
            }
    }
}

export default function Kanban<ResourceT extends Ticket>({
    renderCard,
    columns,
    colSize = 4,
    namespace,
    normalizer,
    searchFilter = {}
}: KanbanProps<ResourceT>) {

    const {update} = useDatasource<ResourceT>(namespace, normalizer);

    const initialColumnRendering: { [key: string]: boolean } = {};
    const initialColumnResources: ResourceState<ResourceT> = {};

    for (const column of columns) {
        initialColumnRendering[arrayIndexFormat(column.col)] = true;
        initialColumnResources[arrayIndexFormat(column.col)] = [];
    }

    const [columnsRendering, setColumnsRendering] = useState(initialColumnRendering);
    const [columnsSize, setColumnsSize] = useState(colSize);
    const [droppedItem, setDroppedItem] = useState<{ fromColumn: string, toColumn: string } | null>(null);

    const [resources, dispatch] = useReducer<Reducer<ResourceState<ResourceT>, ResourceAction<ResourceT>>>(resourceReducer, initialColumnResources);

    const handleToggleColumn = useCallback((column: string) => {
        const columnName = arrayIndexFormat(column);

        setColumnsRendering((prevState) => {
            prevState[columnName] = !prevState[columnName];
            return {...prevState}
        });
    }, []);

    useEffect(() => {
        const numOfColumnDisplayed = Object.entries(columnsRendering)
            .filter(
                ([, value]) => value
            ).length;

        const totalGridSize = numOfColumnDisplayed * colSize;

        if (totalGridSize >= 12) {
            setColumnsSize(colSize);
            return;
        }

        const newSize = 12/numOfColumnDisplayed;

        setColumnsSize((Number.isInteger(newSize) ? newSize : 0) as columnsBeforeScrollRange);
    }, [colSize, columnsRendering]);

    const resetColumnVisibility = () => {
        setColumnsRendering(initialColumnRendering);
    };

    /**
     * On drag end
     */
    const onDragEnd = useCallback(async (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const cols = [];
        for (const column of columns) {
            cols.push(column.col)
        }

        if (cols.indexOf(source.droppableId) < 0) {
            console.error(`Missing source droppableId ${source.droppableId} in type checking`)
            return;
        }

        if (cols.indexOf(destination.droppableId) < 0) {
            console.error(`Missing destination droppableId ${destination.droppableId} in type checking`)
            return;
        }

        if (source.droppableId === destination.droppableId) {
            console.log('reorder is not supported yet')
            //onReorder(source, destination);
        } else {
            // limitation de typescript, les partials d'un type généric ne sont pas bien supporté
            // même si celui-ci a des contraintes
            // https://stackoverflow.com/questions/59279796/typescript-partial-of-a-generic-type
            const resource = {
                id: parseInt(result.draggableId),
                column: destination.droppableId
            } as Partial<ResourceT> & Ticket;

            const res = await update(resource, 'kanban');

            setDroppedItem({
                fromColumn: source.droppableId,
                toColumn: destination.droppableId,
            })

            dispatch({type: ResourceActionsType.MOVE, payload: res, from: arrayIndexFormat(source.droppableId), to: arrayIndexFormat(destination.droppableId)})
        }
    }, [columns, update]);

    const addToColumns = useMemo(() => {
        const callbacks: { [key: string]: (resources: ResourceT[]) => void } = {}

        for (const column of columns) {
            callbacks[arrayIndexFormat(column.col)] = (resources: ResourceT[]) => {
                dispatch({
                    type: ResourceActionsType.ADD,
                    column: arrayIndexFormat(column.col),
                    payload: resources
                });
            }
        }

        return callbacks;
    }, [columns]);

    const colFilters = useMemo(() => {
        const filters: { [key: string]: FilterType } = {}

        for (const column of columns) {
            filters[arrayIndexFormat(column.col)] = {...Object.assign({}, searchFilter || {}, {column: column.col})};
        }

        return filters;
    }, [columns, searchFilter]);

    /*const replaceColumns = useMemo(() => {
        const callbacks: { [key: string]: (resources: ResourceT[]) => void } = {}

        for (const column of columns) {
            callbacks[arrayIndexFormat(column.col)] = (resources: ResourceT[]) => {
                dispatch({
                    type: ResourceActionsType.REPLACE,
                    column: arrayIndexFormat(column.col),
                    payload: resources
                });
            }
        }

        return callbacks;
    }, [columns]);*/

    useEffect(() => {
        if (Object.keys(searchFilter).length === 0) {
            return;
        }
        for (const column of columns) {
            dispatch({
                type: ResourceActionsType.EMPTY,
                column: arrayIndexFormat(column.col),
                payload: []
            });
        }
    }, [searchFilter, columns])

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    className={'btn btn-primary mb-1'}
                    disabled={!hasFalseValue(columnsRendering)}
                >
                    <i className={'fas fa-caret-down'}/>
                </Dropdown.Toggle>
                <Dropdown.Menu className={'bg-light'}>
                    {
                        hasFalseValue(columnsRendering)
                            ?
                                <Dropdown.Item
                                    className={'btn btn-white'}
                                    onClick={resetColumnVisibility}
                                >
                                    Tout afficher
                                </Dropdown.Item>
                            : null
                    }
                    {
                        Object.entries(columnsRendering)
                            .filter(([,rendered]) => !rendered)
                            .map(([key, rendered], index: number) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        className={'btn btn-white'}
                                        onClick={() => {
                                            handleToggleColumn(key);
                                        }}
                                    >
                                        {titleFormat(key)}
                                    </Dropdown.Item>
                                );
                            })
                    }
                </Dropdown.Menu>
            </Dropdown>
            <Row
                className={classNames('d-flex flex-fill flex-nowrap bg-transparent mx-n1')}
                style={{overflowY: 'auto'}}>

                <DragDropContext onDragEnd={onDragEnd}>
                    {
                        columns
                            .filter((column) => {
                                return columnsRendering[arrayIndexFormat(column.col)];
                            }).map((column) => {
                                return (
                                    <StrictModeDroppable droppableId={column.col} key={column.col}>
                                        {
                                            (provided: DroppableProvided) => (
                                                <InfiniteListingController
                                                    normalizer={normalizer}
                                                    namespace={namespace}
                                                    filters={colFilters[arrayIndexFormat(column.col)]}
                                                    resources={resources[arrayIndexFormat(column.col)]}
                                                    setResources={addToColumns[arrayIndexFormat(column.col)]}
                                                    droppedItem={droppedItem}
                                                    orderBy={column.orderBy}
                                                    renderListing={
                                                        (props) => (
                                                            <Column
                                                                {...props}
                                                                onToggleColumn={handleToggleColumn}
                                                                provided={provided}
                                                                column={column}
                                                                colSize={columnsSize}
                                                                renderCard={renderCard}
                                                            />
                                                        )
                                                    }
                                                />
                                            )
                                        }
                                    </StrictModeDroppable>
                                );
                            }
                        )
                    }
                </DragDropContext>
            </Row>
        </>
    );
};
