import {Card, Col} from "react-bootstrap";
import {Draggable, DraggableProvided, DroppableProvided} from "@hello-pangea/dnd";
import {KanbanColumn, KanbanProps} from "./Kanban";
import {Ticket} from "../../model/interface/Ticket";
import {useNavigate} from "react-router-dom";
import Spinner from "../Spinner";
import {renderListingProps} from "../InfiniteListingController";
import {UIEventHandler, useCallback, useRef} from "react";

export interface ColumnProps<T extends Ticket> extends renderListingProps<T>{
    colSize:number,
    provided?:DroppableProvided,
    onToggleColumn?: (column:string) => void,
    column:KanbanColumn,
    renderCard:KanbanProps<T>["renderCard"],
}

export default function Column<T extends Ticket>(props:ColumnProps<T>) {
    const {
        colSize,
        provided,
        onToggleColumn,
        column,
        renderCard,
        isLoading,
        resources,
        totalItems,
        loadNextBatch
    } = props;
    const navigate = useNavigate();

    const listRef = useRef<HTMLUListElement>(null);

    const handleScroll = useCallback<UIEventHandler<HTMLUListElement>>(() => {
        if (!listRef.current) {
            return
        }

        // position du scroll actuelle en pixel
        const currentScroll = listRef.current.scrollTop
        // total de pixel à scroller
        const totalScroll = listRef.current.scrollHeight - listRef.current.clientHeight

        // quand on arrive à la moitié du scroll total
        if (currentScroll >= totalScroll / 2) {
            loadNextBatch()
        }

    }, [loadNextBatch]);

    return (
        <Col
            lg={colSize}
            ref={provided?.innerRef}
            className={'resize-on-drag px-1'}>
            <Card
                className={'bg-white h-100 overflow-hidden'}>
                <Card.Header className={'px-2 pt-2 pb-0'}>
                    <h5 className="header-title">
                        {
                            onToggleColumn
                                ? (
                                    <i
                                        onClick={() => {
                                            onToggleColumn(column.col);
                                        }}
                                        className={'btn fas fa-caret-left'}
                                    ></i>
                                ) : null
                        }
                        {column.label}
                        <span className={'font-13 ms-1 fw-bolder text-primary'}>
                            ({totalItems ?? '...'})
                        </span>
                    </h5>
                </Card.Header>
                <Card.Body
                    className={'d-flex flex-fill flex-column overflow-auto p-2'}
                >
                    {resources.length === 0 && !isLoading ? (<p className="text-center text-muted pt-2 mb-0">Aucun dossier</p>) : null}
                    <ul className="task-list list-unstyled d-flex flex-wrap overflow-auto gap-1"
                        onScroll={handleScroll}
                        ref={listRef}
                        id={column.col}>
                        {
                            resources.map((item) => {
                                if (!item.id) {
                                    return null;
                                }
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id + ''}
                                        index={item.id}
                                    >
                                        {
                                            (provided: DraggableProvided) => (
                                                <li
                                                    className="task flex-fill mb-1"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    onDoubleClick={
                                                        () => navigate(`/import/${item.id}`)
                                                    }
                                                >
                                                    {renderCard(item)}
                                                </li>
                                            )
                                        }
                                    </Draggable>
                                );
                            })}
                        {provided?.placeholder}
                        {isLoading ? (<li className="text-center flex-fill mb-1 w-100"><Spinner/></li>) : null}
                    </ul>
                </Card.Body>
            </Card>
        </Col>
    );
}
